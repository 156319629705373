import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addLeaseTower',
  components: {
    DatePicker
  },
  props: ['leaseTowerRowDetail'],
  data() {
    return {
      unsubscribe: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      vsetCode: null,
      parent_value_set_id: null,
      startDate: null,
      endDate: null,
      start_date_end_date: null,
      version: null,
      approvalStatus: null,
      editMode: false,
      showValueSetModal: false,
      leaseTowerId: 0,
      leaseTowerCode: null,
      leaseTowerName: null,
      leasePrjName: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      },
      flag: false
    };
  },
  mounted() {
    if (this.leaseTowerRowDetail) {
      this.fillRecordFromLeaseTowerParent(this.leaseTowerRowDetail);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
          this.flag = false;
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditLeaseTower();
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.leaseTowerId });
        }
      }
    });
  },
  methods: {
    fillRecordFromLeaseTowerParent(item) {
      this.flag = true;
      this.leasePrjName.value = item.lease_prj_id;
      this.leasePrjName.text = item.lease_prj_name;
      this.leaseTowerCode = item.tower_code;
      this.leaseTowerName = item.tower_name;
      this.leaseTowerId = item.lease_tower_id;
      this.startDate = item.start_date;
      this.endDate = item.end_date;
      this.version = item.version;
      this.approvalStatus = item.approval_status;
    },
    addEditLeaseTower() {
      const payload = {
        lease_prj_id: this.leasePrjName.value,
        lease_tower_id: this.leaseTowerId,
        start_date: this.startDate,
        end_date: this.endDate,
        tower_code: this.leaseTowerCode,
        tower_name: this.leaseTowerName
      };
      this.loader = true;
      this.$store
        .dispatch('leaseTower/addEditLeaseTowers', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrjName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    setDate(dateRange) {
      this.start_date_end_date = commonHelper.setFutureDate(dateRange);
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.leasePrjName.value) {
        this.leasePrjName = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
