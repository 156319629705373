import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import AddLeaseTower from './addleaseTower';
export default {
  name: 'LeaseTower',
  watch: {
    currentPage: function() {
      this.getLeaseTowerList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getLeaseTowerList();
    }
  },
  components: {
    AddLeaseTower
  },
  props: ['showLeaseModal', 'selectedProject', 'showLeaseModalUnit'],
  data() {
    return {
      payload: null,
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      loader: false,
      showValueSetModal: false,
      showAddLeaseModal: false,
      vsetCode: null,
      leasePrj: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      },
      leaseTower: null,
      leaseTowerRowDetail: null,
      leaseTowerData: [],
      leaseTowerFields: [
        {
          key: 'lease_prj_name'
        },
        {
          key: 'tower_code'
        },
        {
          key: 'tower_name'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'version'
        },
        {
          key: 'approval_status'
        }
      ],
      routeName: this.$router.currentRoute.name,
      setModalName: null
    };
  },
  mounted() {
    if (this.showLeaseModal || this.showLeaseModalUnit) {
      this.leasePrj.value = this.selectedProject.value;
      this.leasePrj.text = this.selectedProject.text;
      this.getLeaseTowerList();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showHideLeaseTowerModal(true, 'addTower');
          this.leaseTowerRowDetail = null;
        }
        if (
          actionName === 'download' &&
          !this.showValueSetModal &&
          !this.showAddLeaseModal
        ) {
          this.loader = true;
          const downloadPayload = { ...this.payload };
          downloadPayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadPayload,
            'leaseTower/getLeaseTowersList',
            'lease-tower',
            () => {
              this.loader = false;
            }
          );
        }
      }
    });
  },
  methods: {
    getLeaseTowerList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        lease_project_id: this.leasePrj.value,
        tower_name: this.leaseTower
      };
      this.loader = true;
      this.$store
        .dispatch('leaseTower/getLeaseTowersList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.leaseTowerData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearSearchFilters() {
      this.leasePrj = this.defaultValue;
      this.leaseTower = null;
      this.leaseTowerData = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    rowSelected(item) {
      if (this.showLeaseModal || this.showLeaseModalUnit) {
        this.$emit('getSelectedTower', item);
      } else {
        this.leaseTowerRowDetail = item;
        this.showHideLeaseTowerModal(true);
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrj = {
          value: item.value_set_dtl_id,
          text: item.lease_prj_name
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHideLeaseTowerModal(flag, value) {
      this.showAddLeaseModal = flag;
      this.setModalName = value;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.leasePrj.value) {
        this.leasePrj = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
